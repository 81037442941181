<template>
  <a-modal :visible="showAddress" :width="1000" centered @cancel="handleCancel">
    <template #title>
      <winTitle title="选择地址" sub-title="" />
    </template>
    <a-radio-group v-model="addressIndex">
      <div class="address-info">
        <div  v-for="(item,index) in addressList" :class="addressIndex===index?'card-box  active':'card-box'" :key="index">
          <a-radio name="radio" :value="index">
          </a-radio>
          <div style="width: 100%">
            <div class="address-user">
              <div style="display: flex;align-items: center">
                <span>{{ item.concat }} </span>
                <span>{{ item.concatPhone }}</span>
                <span class="address-def" v-if="item.defaultFlag == 1">默认</span>
              </div>
              <span class="address-edit" @click="handleEdit(item)"><img src="@/assets/edit.png">编辑</span>
            </div>
            <span class="address-ads">{{ item.provinceName }}{{ item.cityName }}{{ item.areaName }}{{ item.address
              }}</span>
          </div>
        </div>

      </div>
    </a-radio-group>
    <template slot="footer">
      <div class="btn-group">
<!--        <img src="@/assets/hand-select.png">-->
<!--        <span>手动选择地址区域</span>-->
        <img src="@/assets/bigPlus.png">
        <span @click="handleAddress">新增收货地址</span>
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleSubmit">
          确认
        </a-button>
      </div>

    </template>
    <AddressModal ref="addressModal" @ok="getAddressList()" />
  </a-modal>
</template>
<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postAction, getAction } from '@/api/manage'
import Captcha from '@/components/tools/captcha.vue'
import WinTitle from '@/components/winTitle/index.vue'
import region from '@/util/region.json'
import AddressModal from '@/views/address/modules/addressModal.vue'
import { getCurBusiness } from '@/util/utils'

export default {
  name: 'address',
  components: {
    AddressModal,
    DrawerView,
    Captcha,
    WinTitle
  },
  data() {
    return {
      showAddress: false,
      addressList: [],
      addressIndex: 0
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEdit(item) {
      this.$refs.addressModal.handleOpen(item)
      this.showAddress = true
    },
    handleCancel() {
      this.showAddress = false
    },
    handleSubmit() {
      this.$emit('ok', this.addressList[this.addressIndex])
      this.showAddress = false
    },

    handleOpen(item) {
      this.showAddress = true
      this.getAddressList()
    },
    getAddressList() {
      const curBusiness = getCurBusiness()
      const payload = {}
      payload.businessId = curBusiness?.id
      payload.pageNum = 1
      payload.pagesSize = 100
      postAction('/address/queryAllByLimit', payload).then((res) => {
        if (res.success) {
          this.addressList = res.data.list || []
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleAddress() {
      this.$refs.addressModal.handleOpen({})
    }
  }
}
</script>

<style lang="less" scoped>
.address-info {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 24px;
  max-height: 500px;
  overflow: auto;
}

.card-box {
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  width: 458px;
  border: 1px solid #D9D9D9;
  //margin-right: 24px;
  border-radius: 4px;
  align-items: center;

  span {
    padding-right: 8px;
  }

}

.active {
  border: 1px solid #FF6026;
}
.address-user {
  display: flex;
  justify-content: space-between;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.address-ads {
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #131212;
  padding-top: 4px;

}

.address-def {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #F9A519;
  padding: 2px 8px;
  background: #F9A5191F;
  border-radius: 2px;
}

.address-edit {
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #FF6026;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    padding-left: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #FF6026;
    padding-right: 16px;
  }

  img {
    width: 16px;
    height: 16px;

  }
}
</style>
