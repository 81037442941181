<!--
 * @Description: 多选字典
 * @Author: zhang zhen
 * @Date: 2023-04-17 21:07:39
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-06-17 16:23:59
 * @FilePath: /page-sass/src/components/plugins/JMultiSelectQuery.vue
-->
<template>
  <div>
    <a-select :value="arrayValue" @change="onChange" :disabled="disabled" mode="multiple" :placeholder="placeholder"
      :getPopupContainer="getParentContainer" optionFilterProp="children" :filterOption="filterOption" :maxTagCount="maxTagCount"
      allowClear :showArrow="true">
      <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
      <a-select-option v-for="(item, index) in dictOptions" :key="index" :value="item.wrapType">
        <a-checkbox :checked="arrayValue.includes(item.wrapType)">
          <span style="display: inline-block;width: 100%" :title="item.wrapTypeDictName">
            {{ item.wrapTypeDictName }}
          </span>
        </a-checkbox>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { postAction } from '@/api/manage'
export default {
  name: 'MultiSelectTag',
  props: {
    dictCode: String,
    placeholder: String,
    disabled: Boolean,
    value: String,
    options: Array,
    spliter: {
      type: String,
      required: false,
      default: ','
    },
    maxTagCount: {
      type: Number,
      default: 2
    },
    popContainer: {
      type: String,
      default: '',
      required: false
    },
    optionType: {
      type: String,
      default: 'select'
    }
  },
  data() {
    return {
      dictOptions: [],
      tagType: '',
      arrayValue: !this.value ? [] : this.value.split(this.spliter)
    }
  },
  watch: {
    options: function (val) {
      this.setCurrentDictOptions(val)
    },
    dictCode: {
      immediate: true,
      handler() {
        this.initDictData()
      }
    },
    value(val) {
      if (!val) {
        this.arrayValue = []
      } else {
        console.log(val)
        this.arrayValue = this.value.split(this.spliter)
      }
    }
  },
  methods: {
    initDictData() {
      postAction('/v2/purchases/queryWrapTypeByPurchaseType', { purchaseType: this.dictCode, wrapTypes: [] }).then(res => {
        if (res.success) {
          this.dictOptions = res.data
        }
      })
    },
    onChange(selectedValue) {
      //   this.$forceUpdate()
      console.log(selectedValue)
      this.$emit('change', selectedValue.join(this.spliter))
    },
    setCurrentDictOptions(dictOptions) {
      this.dictOptions = dictOptions
    },
    getCurrentDictOptions() {
      return this.dictOptions
    },
    getParentContainer(node) {
      if (!this.popContainer) {
        return node.parentNode
      } else {
        return document.querySelector(this.popContainer)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>
<style lang="less">
.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #f9f9f9 !important;

  .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 12px !important;
  }

  .ant-checkbox-checked {
    display: none;
  }
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon {
  display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}

.ant-select-selection--multiple .ant-select-selection__rendered > ul > li  {
  height: 30px;
  line-height: 28px;
}

.ant-select-disabled {
  .ant-select-selection__choice .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.33) !important;
  }
}
</style>
