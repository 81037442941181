<template>
  <div class="main-content">
    <div class="view-content">
      <div class="header-content">
        <img src="/avatar.png" alt="" class="avatar" />
        <div class="info-box">
          <div class="main-title">{{ baseInfo.purchaseTitle || '-' }}</div>
          <div class="tag" v-if="roleType == 'PERSON' && tradeIdentity == '2'">个人/采购商</div>
          <div class="tag" v-if="roleType == 'BUSINESS' && tradeIdentity == '2'">企业/采购商</div>
          <div class="tag" v-if="roleType == 'BUSINESS' && tradeIdentity == '0'">企业/供应商</div>
        </div>

        <!-- <a-button type="link" class="edit-btn" @click="handleEdit('CompleteInformation')">
                    <img src="~@/assets/newHomePage/edit.png" alt="" class="edit">
                    <span>编辑</span>
                </a-button> -->
      </div>
      <div class="line-block"></div>
      <a-card title="交易流程" class="card-box">
        <a-steps :current="0" progress-dot>
          <a-step title="发布需求" />
          <a-step title="推荐供应商" />
          <a-step title="供应商参与" />
          <a-step title="咨询议价/确定供应商" />
        </a-steps>
      </a-card>
      <!-- 基本信息 -->
      <div class="card-title">基本信息</div>
      <a-descriptions :column="2" bordered style="margin-bottom: 25px">
        <a-descriptions-item label="供应商参与数量">
          {{ baseInfo.participantCount || '-' }}
        </a-descriptions-item>

        <a-descriptions-item label="需求类型">
          {{ baseInfo.purchaseTypeDictName || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="报价截止日期">
          {{ baseInfo.quotationDeadline || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="付款方式">
          {{ baseInfo.paymentMethodDictName || '-' }}
        </a-descriptions-item>
      </a-descriptions>
      <div class="card-title">采购信息</div>
      <a-descriptions :column="2" bordered style="margin-bottom: 25px">
        <a-descriptions-item label="采购商名称" :span="2">
          {{ basicInfo.businessName || '个人' }}
        </a-descriptions-item>
        <!-- <a-descriptions-item label="产品类别">
                    {{ basicInfo.productCategoryDictName || '-' }}
                </a-descriptions-item> -->

        <a-descriptions-item label="年包材需求总额">
          {{ basicInfo.packagingCostTotalDictName || '-' }}
        </a-descriptions-item>
        <!-- <a-descriptions-item label="单次最小下单量">
                    {{ basicInfo.minOrderCountDictName || '-' }}
                </a-descriptions-item>
                <a-descriptions-item label="设计要求">
                    {{ basicInfo.designDictName || '-' }}
                </a-descriptions-item>
                <a-descriptions-item label="打样测试">
                    {{ basicInfo.sampleTestDictName || '-' }}
                </a-descriptions-item>
                <a-descriptions-item label="认证要求">
                    {{ basicInfo.authMethodDictName || '-' }}
                </a-descriptions-item>
                <a-descriptions-item label="其他服务">
                    {{ basicInfo.otherServiceDictName || '-' }}
                </a-descriptions-item>
                <a-descriptions-item label="下单周期">
                    {{ basicInfo.orderCycleDictName || '-' }}
                </a-descriptions-item> -->
        <a-descriptions-item label="要货方式">
          {{ basicInfo.purchasingMethodDictName || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="供货区域">
          {{ basicInfo.providerAreaDictName || '-' }}{{ baseInfo.providerAreaRegionDictName || '' }}
        </a-descriptions-item>
      </a-descriptions>

      <div class="card-title">
        {{ formModel == 1 ? '采购详情(模板填写)' : '采购详情' }}
      </div>
      <!-- 直接上传 -->
      <template v-if="formModel == 1">
        <splitLine label="瓦楞纸包装" />
        <a-descriptions :column="2" bordered>
          <a-descriptions-item label="包装用途">
            {{ baseInfo.wrapTypeDictName || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="瓦楞箱型">
            {{ baseInfo.purchaseTypeDictName || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <splitLine label="上传文件" />
        <div class="excel-box">
          <div class="top-box">
            <img class="excel-icon" src="~@/assets/excel.png" alt="" />
            <a @click="handleDownLoadFile(baseInfo.templateFile)">下载</a>
          </div>
        </div>
        <splitLine label="附件" />
        <div class="files-list">
          <div class="files-list-item" v-for="item in fileList" :key="item.name">
            <div class="cover">
              <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
              <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
            </div>
            <div class="option-area">
              <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
              <div class="split-line"></div>
              <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
            </div>
            <div class="info-card inside">
              <div class="sub_title">{{ item.fileName }}</div>
              <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <a-tabs type="card">
          <a-tab-pane
            :key="index"
            :tab="tabTitleList[index] ? tabTitleList[index] : `散件${index + 1}`"
            v-for="(i, index) in purchaseItem"
          >
            <RequirementInfoView :formItemDtoList="i.formInfo" />
            <splitLine label="附件" v-show="i.fileList && i.fileList.length" />
            <div class="files-list">
              <div class="files-list-item" v-for="item in i.fileList" :key="item.name">
                <div class="cover">
                  <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                  <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                </div>
                <div class="option-area">
                  <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                  <div class="split-line"></div>
                  <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                </div>
                <div class="info-card inside">
                  <div class="sub_title">{{ item.fileName }}</div>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <!-- <template v-if="baseInfo.templateFile">
                    <splitLine label="上传文件" />
                    <div class="excel-box">
                        <div class="top-box">
                            <img class="excel-icon" src="~@/assets/excel.png" alt="" />
                            <a @click="handleDownLoadFile(baseInfo.templateFile)">下载</a>
                        </div>
                    </div>
                </template> -->
        <template v-if="fileList && fileList.length">
          <splitLine label="附件" />
          <div class="files-list">
            <div class="files-list-item" v-for="item in fileList" :key="item.name">
              <div class="cover">
                <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
              </div>
              <div class="option-area">
                <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                <div class="split-line"></div>
                <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
              </div>
              <div class="info-card inside">
                <div class="sub_title">{{ item.fileName }}</div>
                <!-- <div class="labels">文件大小: -K</div>
                                <div class="labels">上传日期: -</div> -->
              </div>
            </div>
          </div>
        </template>
      </template>
      <div class="action-btn">
        <a-button @click="handleEdit('CompleteInformation')" :loading="loading">修改需求</a-button>
        <a-button type="primary" style="margin-left: 8px" :loading="loading" @click="handleSubmit">发布需求</a-button>
      </div>
      <!-- <div class="action-btn" @click="handleSubmit">
                发布需求
            </div>
            <div class="action-btn" @click="handleEdit('CompleteInformation')">
                取 消
            </div> -->
    </div>
    <a-modal
      :visible="showInfo"
      :footer="null"
      :width="480"
      :closable="false"
      :maskClosable="false"
      centered
      :bodyStyle="{ padding: 0 }"
    >
      <div class="content-box">
        <img src="~@/assets/successIcon.png" alt="" class="icon" />
        <div class="title">发布成功</div>
        <div class="desc">请查看系统为您推荐的供应商,或等待供应商报价~</div>
        <a-space>
            <a-button style="color: #000000A6;" @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleJumpDirector">查看</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { postAction, postQueryAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea'
import { mapGetters } from 'vuex'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import splitLine from './components/splitLine.vue'
import RequirementInfoView from '@/components/plugins/RequirementInfoView.vue'
Vue.use(VueViewer)
export default {
  name: 'RequirementConfirmation',
  components: {
    EmptyArea,
    splitLine,
    RequirementInfoView,
  },
  props: {
    formType: {
      type: String,
      default: '',
    },
    purchaseId: {
      type: String,
    },
  },
  data() {
    return {
      showInfo: false,
      basicInfo: {},
      tradeIdentityList: [
        {
          label: '下载模板填写',
          value: 1,
        },
        {
          label: '直接填写',
          value: 2,
        },
      ],
      formModel: 2,
      priceBudget: 0,
      showMore: false,
      baseInfo: {},
      purchaseItem: [],
      loading: false,
      excelName: '',
      fileList: [],
      roleType: 'BUSINESS',
      tradeIdentity: '0',
      showFile: true,
      tabTitleList: [],
    }
  },
  created() {
    const { tradeIdentity, roleType } = this.setUserInfo()
    this.roleType = roleType
    this.tradeIdentity = tradeIdentity
    this.handleLoadInfo()
    this.showInfo = false
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleLoadSaleManInfo() {
      postQueryAction('/business/queryByBusinessId', {
        businessId: this.setUserInfo().businessId,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          const { capacity, supplierPurchasePlan = {}, tradeIdentity = {}, businessName } = data
          this.basicInfo = Object.assign({ businessName }, tradeIdentity == 0 ? supplierPurchasePlan : capacity)
        }
      })
    },
    handleInitForm() {},
    handleLoadInfo() {
      this.handleLoadSaleManInfo() // 加载采购商信息
      postAction(`/v2/purchases/showPurList/${this.purchaseId}`).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { itemList } = data
          !itemList || (itemList.length == 0 && (this.formModel = 1))
          if (data.uploadFile) {
            let fileList = data.uploadFile.split(',')
            fileList.map((i) => {
              let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
              this.fileList.push({
                fileName,
                url: i,
                isImage: fileName.includes('.jpg') || fileName.includes('.png'),
              })
            })
          }
          this.baseInfo = data
          this.priceBudget = 0
          this.purchaseItem = []
          itemList.map((i) => {
            const { wrapTypeDictName } = i
            this.tabTitleList.push(`${data.purchaseTypeDictName || ''} - ${wrapTypeDictName || ''}`)
            // 计算合计金额
            let num = 0,
              price = 0
            let targetNumItem = i.itemForms.find((item) => item.fieldFlag == 'num')
            let targetPriceItem = i.itemForms.find((item) => item.fieldFlag == 'price')
            if (targetNumItem && targetPriceItem) {
              num = targetNumItem.filedValue || 0
              price = targetPriceItem.filedValue || 0
            }
            this.priceBudget += Number(num) * Number(price)
            // 上传图片回显示
            let baseUploadItem = i.itemForms.find((item) => item.componentType == 'FILE'),
              fileList = []
            this.showFile = !!baseUploadItem // 显示文件上
            if (baseUploadItem && baseUploadItem.filedValue) {
              let fileArray = baseUploadItem.filedValue.split(',')
              fileArray.map((i) => {
                let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
                fileList.push({
                  fileName,
                  url: i,
                  isImage: fileName.includes('.jpg') || fileName.includes('.png'),
                })
              })
            }
            let formItemDtoList = i.itemForms.filter((item) => item.componentType != 'FILE')
            this.purchaseItem.push({
              formInfo: {
                arr: formItemDtoList,
                wrapTypeDictName,
              },
              fileList,
            })
          })
        } else {
          this.$message.warning({
            content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${message}`}</span>,
            icon: (h) => <img src="./noPass.png" width="21" height="21" />,
          })
        }
      })
    },
    handleDownLoadFile(url) {
      window.open(url)
    },
    handleEdit(key) {
      this.$emit('handleEmitEdit', {
        key,
      })
    },
    handleSubmit() {
      this.loading = true
      postAction(`/v2/purchases/publish/${this.purchaseId}`)
        .then((res) => {
          const { success, message } = res
          this.loading = false
          if (success) {
            this.showInfo = true //
          } else {
            this.$message.warning({
              content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${message}`}</span>,
              icon: (h) => <img src="/noPass.png" width="21" height="21" />,
            })
          }
        })
        .catch((res) => {
          console.log('error submit!!', res)
          this.loading = false
        })
    },
    show(url) {
      this.$viewerApi({
        images: [url],
      })
    },
    handleCancel() {
        this.showInfo = false;
        this.$router.push('/requirement/requirementList')
    },
    /* 跳转页面判断 */
    handleJumpDirector() {
      // 区分下供应商和采购商详情不一样
      // <!-- tradeIdentity：0-供应商，1-服务商，2-采购商 -->
      this.showInfo = false;
      const { purchaseTitle, purchaseNo, purchaseId } = this.baseInfo
      // purchaseId purchaseTitle purchaseNo
      // 1. 采购商
      this.$router.push(
        `/requirement/requirementList`
      )
      // if (this.setUserInfo().tradeIdentity == '2') {
      //     // 采购商
      //     this.$router.push(
      //         `/requirement/ForUserAndPurchaseDetails?purchaseId=${purchaseId}&purchaseTitle=${purchaseTitle}&purchaseNo=${purchaseNo}&formType=${this.formType}&activeTab=RecommendedInfo`
      //     )
      // } else {
      //     // 供应商
      //     this.$router.push(
      //         `/requirement/forFacilitatorDetails?purchaseTitle=${purchaseTitle}&purchaseId=${purchaseId}&purchaseNo=${purchaseNo}&formType=${this.formType}`
      //     )
      // }
    },
  },
}
</script>

<style lang="less" scoped>
.main-content {
  width: 1184px;
  max-width: 1184px;
  display: flex;
  justify-content: space-between;
}

.view-content {
  flex: 1 0 0;
  min-width: 0;
  border-radius: 6px;
  background-color: #fff;
  padding: 16px 21px 25px;
  box-sizing: border-box;
  // margin-right: 16px;

  .header-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      width: 40px;
      margin-right: 8px;
    }

    .info-box {
      flex: 1 0 0;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .main-title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #131212;
      }

      .tag {
        width: 86px;
        height: 24px;
        border-radius: 4px;
        background-color: #fafafa;
        border: 1px solid #d9d9d933;
        font-size: 12px;
        color: #595959;
        text-align: center;
        line-height: 22px;
        margin-top: 8px;
        cursor: pointer;
      }
    }

    .edit-btn {
      display: flex;
      align-items: center;

      .edit {
        width: 16px;
        margin-right: 3px;
      }
    }
  }

  .line-block {
    width: 100%;
    height: 4px;
    background-color: #f8f9f9;
    margin: 16px 0 28px;
  }

  .card-box {
    border-radius: 6px;
    margin-bottom: 25px;

    ::v-deep .ant-card-head {
      background-color: #f4f5f7;
      font-size: 15px;
      color: #131212;

      .ant-card-head-title {
        padding: 15px 0;
      }
    }

    ::v-deep .ant-steps {
      width: 100%;
    }

    ::v-deep .ant-steps-item-title {
      font-size: 14px;
    }

    ::v-deep .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: transparent;
      border: 2px solid #8d8e99;
    }

    ::v-deep .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
      width: 8px;
      height: 8px;
    }

    ::v-deep .ant-steps-item-active .ant-steps-item-title {
      color: #ff6e2d;
    }
  }

  .card-title {
    width: 100%;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #131212;
    border-bottom: 1px solid #efefef;
    margin-top: 8px;
    padding-bottom: 12px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    &::before {
      display: block;
      content: '';
      width: 5px;
      height: 16px;
      background: #ff6e2d;
      margin-right: 8px;
    }
  }

  .sub-title-card {
    width: 100%;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #131212;
    border-bottom: 1px solid #efefef;
    padding-bottom: 12px;
  }

  .excel-box {
    height: 64px;
    background-color: #f7f8fa;
    border: 1px dashed #e8e9eb;
    margin-top: 8px;
    margin-bottom: 15px;
    border-radius: 2px;
    padding: 12px;
    box-sizing: border-box;

    .excel-icon {
      width: 13px;
      margin-right: 8px;
    }

    a {
      font-size: 12px;
    }
  }
}

.calculate-box {
  width: 284px;
  height: 239px;
  background: url('~@/assets/newHomePage/bg-4.png') no-repeat;
  background-size: 100% 100%;
  padding: 24px 25px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .calculate-title {
    width: 143px;
  }

  .numberInput {
    width: 100%;
    border-color: #d9d9d9;
    height: 38px;
    line-height: 38px;

    ::v-deep .ant-input-number-input {
      font-size: 14px;
      color: #ff6e2d;
      font-weight: 600;
    }

    ::v-deep .ant-input-number-handler {
      display: none;
    }

    ::v-deep .ant-input-number-handler-wrap {
      border-left: none;
      opacity: 1;

      &::before {
        display: inline-block;
        content: '元';
        color: #ff6e2d;
        font-weight: 600;
      }
    }
  }

  .action-btn {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background: linear-gradient(268.63deg, #ff6e2d 0%, #f7ba1e 123.51%);
    line-height: 38px;
    color: #fff;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:last-of-type {
      border: 1px solid #ff6e2d;
      color: #ff6e2d;
      background: transparent;
    }
  }
}

@import '~@/styles/form.less';

.action-btn {
  margin-top: 60px;
  text-align: center;

  .ant-btn {
    width: 104px;
    height: 38px;
    line-height: 36px;
    font-weight: 500;

    &:not(.ant-btn-primary) {
      color: #262626;
      border-color: #d9d9d9;
    }
  }
}

.content-box {
  width: 100%;
  height: 362px;
  padding: 30px 15px 15px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .icon {
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
  }
  .title {
    font-family: PingFang SC;
    font-size: 26px;
    font-weight: 600;
    line-height: 36.4px;
    text-align: center;
    color: #262626;
  }
  .desc {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #262626;
  }
  .ant-btn {
    width: 81px;
    height: 38px;
  }
}
</style>
